exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-application-development-tsx": () => import("./../../../src/pages/application-development.tsx" /* webpackChunkName: "component---src-pages-application-development-tsx" */),
  "component---src-pages-blockchain-tsx": () => import("./../../../src/pages/blockchain.tsx" /* webpackChunkName: "component---src-pages-blockchain-tsx" */),
  "component---src-pages-cloud-migration-tsx": () => import("./../../../src/pages/cloud-migration.tsx" /* webpackChunkName: "component---src-pages-cloud-migration-tsx" */),
  "component---src-pages-consulting-services-tsx": () => import("./../../../src/pages/consulting-services.tsx" /* webpackChunkName: "component---src-pages-consulting-services-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-customer-management-tsx": () => import("./../../../src/pages/customer-management.tsx" /* webpackChunkName: "component---src-pages-customer-management-tsx" */),
  "component---src-pages-data-analytics-tsx": () => import("./../../../src/pages/data-analytics.tsx" /* webpackChunkName: "component---src-pages-data-analytics-tsx" */),
  "component---src-pages-data-center-and-cloud-tsx": () => import("./../../../src/pages/data-center-and-cloud.tsx" /* webpackChunkName: "component---src-pages-data-center-and-cloud-tsx" */),
  "component---src-pages-data-protection-storage-tsx": () => import("./../../../src/pages/data-protection-storage.tsx" /* webpackChunkName: "component---src-pages-data-protection-storage-tsx" */),
  "component---src-pages-data-science-tsx": () => import("./../../../src/pages/data-science.tsx" /* webpackChunkName: "component---src-pages-data-science-tsx" */),
  "component---src-pages-digital-marketing-tsx": () => import("./../../../src/pages/digital-marketing.tsx" /* webpackChunkName: "component---src-pages-digital-marketing-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-e-commerce-tsx": () => import("./../../../src/pages/e-commerce.tsx" /* webpackChunkName: "component---src-pages-e-commerce-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infrastructure-tsx": () => import("./../../../src/pages/infrastructure.tsx" /* webpackChunkName: "component---src-pages-infrastructure-tsx" */),
  "component---src-pages-internet-of-things-tsx": () => import("./../../../src/pages/internet-of-things.tsx" /* webpackChunkName: "component---src-pages-internet-of-things-tsx" */),
  "component---src-pages-managed-cloud-tsx": () => import("./../../../src/pages/managed-cloud.tsx" /* webpackChunkName: "component---src-pages-managed-cloud-tsx" */),
  "component---src-pages-managed-services-tsx": () => import("./../../../src/pages/managed-services.tsx" /* webpackChunkName: "component---src-pages-managed-services-tsx" */),
  "component---src-pages-medical-tsx": () => import("./../../../src/pages/medical.tsx" /* webpackChunkName: "component---src-pages-medical-tsx" */),
  "component---src-pages-merchandising-planning-tsx": () => import("./../../../src/pages/merchandising-planning.tsx" /* webpackChunkName: "component---src-pages-merchandising-planning-tsx" */),
  "component---src-pages-news-and-press-tsx": () => import("./../../../src/pages/news-and-press.tsx" /* webpackChunkName: "component---src-pages-news-and-press-tsx" */),
  "component---src-pages-omni-channel-solutions-tsx": () => import("./../../../src/pages/omni-channel-solutions.tsx" /* webpackChunkName: "component---src-pages-omni-channel-solutions-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-payment-processing-tsx": () => import("./../../../src/pages/payment-processing.tsx" /* webpackChunkName: "component---src-pages-payment-processing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-professional-services-tsx": () => import("./../../../src/pages/professional-services.tsx" /* webpackChunkName: "component---src-pages-professional-services-tsx" */),
  "component---src-pages-refund-policy-tsx": () => import("./../../../src/pages/refund-policy.tsx" /* webpackChunkName: "component---src-pages-refund-policy-tsx" */),
  "component---src-pages-regulatory-compliance-tsx": () => import("./../../../src/pages/regulatory-compliance.tsx" /* webpackChunkName: "component---src-pages-regulatory-compliance-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-stores-tsx": () => import("./../../../src/pages/stores.tsx" /* webpackChunkName: "component---src-pages-stores-tsx" */),
  "component---src-pages-supply-chain-tsx": () => import("./../../../src/pages/supply-chain.tsx" /* webpackChunkName: "component---src-pages-supply-chain-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-transportation-tsx": () => import("./../../../src/pages/transportation.tsx" /* webpackChunkName: "component---src-pages-transportation-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../../../src/pages/volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */)
}

